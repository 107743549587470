import { useState, useEffect } from 'react';
import { getToken, setToken } from '../api/axios-client';

export default function useAuth() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (!isAuth) {
      const token = getToken();
      if (token) {
        setToken(token);
        setIsAuth(true);
      }
    }
  }, [isAuth]);

  return isAuth;
}
