import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { FormConfig, renderFormConfig } from '../../utils/form';
import Ui from '../../ui';
import { LoginSchema } from './schemas';
import { ApiAuthUser } from '../../project-types/users/api-types';

const { Input, Title, Button } = Ui;

interface LoginFormProps {
  onFormSubmit: (values: ApiAuthUser['data']) => void;
}

export default function LoginForm({ onFormSubmit }: LoginFormProps) {
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: onFormSubmit,
  });

  const formConfig: FormConfig = [
    {
      title: 'User name',
      name: 'username',
      component: StyledInput,
      props: {
        type: 'string',
      },
    },
    {
      title: 'Password',
      name: 'password',
      component: StyledInput,
      props: {
        inputType: 'passwordInput',
      },
    },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Title>Admin login</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButton type="submit">Sign In</StyledButton>
    </Form>
  );
}

const Form = styled.form`
  padding: 50px;
`;

const StyledInput = styled(Input)`
  max-width: 350px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 350px;
`;
