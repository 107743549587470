import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  gap: 30px;
  padding: 30px;
`;

export default Form;
