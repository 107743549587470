import styled from 'styled-components/macro';
import { Select, Input } from 'antd';

const { TextArea } = Input;

interface MultilangInputProps {
  languages: Array<string>;
  value: { [key: string]: string };
  setFieldValue: (field: string, value: { [key: string]: string }) => void;
  name: string;
  inputType?: 'input' | 'textarea' | 'tags';
  placeholder?: string;
}

export default function MultilangInput({
  languages, value, setFieldValue, name, inputType = 'input', placeholder = '',
}: MultilangInputProps) {
  return (
    <Container>
      <InputsContainer>
        {languages.map((lang) => (
          <InputItem isTextArea={inputType === 'textarea'} key={lang}>
            <LocaleBox isTextArea={inputType === 'textarea'}>{lang}</LocaleBox>
            {inputType === 'input' && <Input dir={lang === 'he' ? 'rtl' : 'ltr'} value={value[lang]} placeholder={placeholder} onChange={(event) => setFieldValue(name, { ...value, [lang]: event.target.value })} />}
            {inputType === 'tags' && <Select style={{ width: '100%' }} placeholder={placeholder} mode="tags" value={value[lang]} onChange={(val) => setFieldValue(name, { ...value, [lang]: val })} />}
            {inputType === 'textarea' && <TextArea dir={lang === 'he' ? 'rtl' : 'ltr'} rows={5} value={value[lang]} onChange={(event) => setFieldValue(name, { ...value, [lang]: event.target.value })} placeholder={placeholder} />}
          </InputItem>
        ))}
      </InputsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const InputItem = styled.div<{ isTextArea: boolean }>`
  display: flex;
  flex-direction: ${({ isTextArea }) => (!isTextArea ? 'row' : 'column')};
`;

const LocaleBox = styled.div<{ isTextArea: boolean }>`
  border: 1px solid #d9d9d9;
  border-right: ${({ isTextArea }) => (!isTextArea && 'none')};
  border-bottom: ${({ isTextArea }) => (isTextArea && 'none')};
  padding: 4px 11px;
  border-radius: 2px;
  width: ${({ isTextArea }) => (!isTextArea ? '50px' : '100%')};
  text-align: center;
`;
