import * as yup from 'yup';
import { VideoFormType } from './types';

export const VideoSchema: yup.SchemaOf<Omit<VideoFormType, 'coverImage' | 'logo' | 'mainPageCell' | 'logoMaxWidth'>> = yup.object({
  title: yup.object().shape({ en: yup.string().required() }).required(),
  videoSrc: yup.string().required(),
  description: yup.object().shape({ en: yup.string().required() }).required(),
  typeOfWork: yup.object().shape({ en: yup.array().min(1).required() }).required(),
  category: yup.object().shape({ en: yup.array().min(1).required() }).required(),
  url: yup.string().required(),
});

export const CreateVideoSchema: yup.SchemaOf<Omit<VideoFormType, 'mainPageCell' | 'logo' | 'logoMaxWidth'>> = yup.object({
  coverImage: yup.array().min(1).required(),
}).concat(VideoSchema);
