import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import Button from '../../ui/Button';
import { VideoTableRow } from './types';
import ObjectsTable from './VideosTable';
import { useVideosQuery } from '../../api/features/videoApi';
import ROUTER_CONFIG from '../../router/router-config';

export default function VideoList() {
  const { push } = useHistory();
  const { data } = useVideosQuery();

  const onCreateButtonClickHandler = () => push(ROUTER_CONFIG.VIDEOS_FORM.getPath());
  const onTableItemClickHandler = (id: string) => push(ROUTER_CONFIG.VIDEO_PAGE.getPath({ id }));

  const tableData: Array<VideoTableRow> = data ? data.data.map((video) => ({
    key: Object.values(video.title)[0],
    name: Object.values(video.title)[0],
    linkToPage: video.id,
  })) : [];

  return (
    <Container>
      <StyledButton onClick={onCreateButtonClickHandler}>Create</StyledButton>
      <ObjectsTable tableData={tableData} onTableItemClick={onTableItemClickHandler} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
  min-width: 230px;
  padding: 10px;
`;
