import styled from 'styled-components/macro';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { renderFormConfig, FormConfig } from '../../../utils/form';
import Ui from '../../../ui';
import { CreateVideoSchema, VideoSchema } from '../../../project-types/video/schemas';
import { VideoFormType } from '../../../project-types/video/types';
import { ResponseVideoDto } from '../../../api/API_GENERATED';

interface VideoFormProps {
  onFormSubmit: (formData: VideoFormType) => void;
  languages: Array<string>;
  initialValues?: ResponseVideoDto;
  onDeleteClickHandler?: () => void;
}

export default function VideoForm({
  onFormSubmit, languages, initialValues, onDeleteClickHandler,
}: VideoFormProps) {
  const formik = useFormik({
    initialValues: {
      title: { en: '' },
      videoSrc: '',
      description: { en: '' },
      typeOfWork: { en: [] },
      category: { en: [] },
      mainPageCell: null,
      coverImage: [],
      logo: [],
      url: '',
      logoMaxWidth: null,
    },
    validationSchema: initialValues ? VideoSchema : CreateVideoSchema,
    onSubmit: (values) => onFormSubmit(values),
  });

  useEffect(() => {
    if (!initialValues) return;

    formik.setValues(initialValues as any);
  }, [initialValues]);

  const formConfig: FormConfig = [
    {
      title: 'Title',
      name: 'title',
      component: Ui.MultilangInput,
      props: {
        languages,
      },
    },
    {
      title: 'Video url',
      name: 'videoSrc',
      component: Ui.Input,
      props: {
        addonBefore: 'https://www.youtube.com/embed/',
        placeholder: 'Youtube Video ID',
      },
    },
    {
      title: 'Description',
      name: 'description',
      component: Ui.MultilangInput,
      props: {
        languages,
        inputType: 'textarea',
      },
    },
    {
      title: 'Type of work tags',
      name: 'typeOfWork',
      component: Ui.MultilangInput,
      props: {
        languages,
        inputType: 'tags',
      },
    },
    {
      title: 'Categories tags',
      name: 'category',
      component: Ui.MultilangInput,
      props: {
        languages,
        inputType: 'tags',
      },
    },
    {
      title: 'URL alias (example: new-video-url-alias)',
      name: 'url',
      component: Ui.Input,
    },
    {
      title: 'Place in main page grid (optional)',
      name: 'mainPageCell',
      component: Ui.Input,
      containerStyle: { flexDirection: 'row', alignItems: 'center', gap: 10 },
      props: {
        inputType: 'numberInput',
        maxNumber: '4',
      },
    },
  ];

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <MainPageGrid>
        <Place1 isSelect={formik.values.mainPageCell === 1}>1</Place1>
        <Place2 isSelect={formik.values.mainPageCell === 3}>3</Place2>
        <Place3 isSelect={formik.values.mainPageCell === 4}>4</Place3>
        <Place4 isSelect={formik.values.mainPageCell === 2}>2</Place4>
      </MainPageGrid>
      <UploadsContainer>
        <Ui.ImageUploader value={formik.values.logo} onChangeHandler={(value) => formik.setFieldValue('logo', value)} label="Upload logo image (optional)" />
        <Ui.ImageUploader value={formik.values.coverImage} onChangeHandler={(value) => formik.setFieldValue('coverImage', value)} label="Upload cover image" />
      </UploadsContainer>
      {renderFormConfig([
        {
          title: 'Maximum width of logo in percent (default 100%)',
          name: 'logoMaxWidth',
          component: Ui.Input,
          containerStyle: { flexDirection: 'row', alignItems: 'center', gap: 10 },
          props: {
            inputType: 'numberInput',
            maxNumber: '100',
          },
        },
      ], formik)}
      <ButtonContainer>
        <StyledButton type="submit">{initialValues ? 'Update' : 'Create'}</StyledButton>
        {initialValues && <StyledButton onClick={onDeleteClickHandler} appearence="danger">Delete</StyledButton>}
      </ButtonContainer>
    </StyledForm>
  );
}

const UploadsContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const StyledForm = styled(Ui.Form)`
  padding: 30px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`;

const StyledButton = styled(Ui.Button)`
  width: 100%;
`;

const MainPageGrid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  grid-template-rows: repeat(2, 150px);
`;

const Place = styled.div<{ isSelect: boolean }>`
  background: white;
  border: ${({ isSelect }) => (isSelect ? '4px solid #34568B' : '2px solid wheat')};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ isSelect }) => (isSelect ? '#34568B' : 'wheat')};;
  font-size: 24px;
`;

const Place1 = styled(Place)<{ isSelect: boolean }>`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
`;

const Place2 = styled(Place)<{ isSelect: boolean }>`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
`;

const Place3 = styled(Place)<{ isSelect: boolean }>`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
`;

const Place4 = styled(Place)<{ isSelect: boolean }>`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;
