import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { DashboardOutlined, LoginOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import ROUTER_CONFIG from '../router/router-config';
import { removeToken } from '../api/axios-client';

interface SideBarProps {
  isAuth: boolean;
}

export default function SideBar({ isAuth }: SideBarProps) {
  const serializeRouteName = (route: string) => route.split('_').join(' ').toLowerCase();
  const onLogoutClickHandler = () => removeToken();

  return (
    <div>
      {isAuth && (
      <>
        <Gapper />
        <Menu theme="dark" mode="inline">
          {Object.entries(ROUTER_CONFIG).map(([key, route], index) => {
            if (route.isMenu) {
              return (
                <Menu.Item key={index}>
                  <DashboardOutlined />
                  <span>{serializeRouteName(key)}</span>
                  <Link to={route.path} />
                </Menu.Item>
              );
            }
          })}
          <Menu.Item onClick={onLogoutClickHandler}>
            <LoginOutlined />
            <span>Log out</span>
          </Menu.Item>
        </Menu>
      </>
      )}
    </div>
  );
}

const Gapper = styled.div`
  height: 32px;
  background: #001529;
  margin: 16px;
`;
