import styled from 'styled-components/macro';
import { useParams, useHistory } from 'react-router';
import VideoForm from './VideoForm';
import { VideoFormType } from '../../../project-types/video/types';
import {
  useCreateVideoAction, useDeleteVideoAction, useUpdateVideoAction, useVideoQuery,
} from '../../../api/features/videoApi';
import ROUTER_CONFIG from '../../../router/router-config';

const selectedLanguages = ['en', 'he'];

export default function VideoPage() {
  // const [selectedLanguages, setSelectedLanguages] = useState<Array<string>>(['en']);

  const { id } = useParams<{ id: string | undefined }>();
  const { push } = useHistory();

  const createVideo = useCreateVideoAction();
  const updateVideo = useUpdateVideoAction();
  const deleteVideo = useDeleteVideoAction();
  const { data } = useVideoQuery(id);

  // useEffect(() => {
  //   if (!id) return;

  //   if (data && data.data) {
  //     setSelectedLanguages(Object.keys(data.data.title));
  //   }
  // }, [data, id]);

  // const localeOptions: Array<{ locale: string, langName: string | undefined }> = Object.keys(languages.getAlpha2Codes()).map((locale: string) => ({ locale, langName: languages.getName(locale, 'en') }));

  const onDeleteClickHandler = () => {
    if (!id) return;

    deleteVideo(id);
    push(ROUTER_CONFIG.VIDEOS_LIST.getPath());
  };

  const onFormSubmit = (formValues: VideoFormType) => {
    const {
      coverImage, logo, url, ...values
    } = formValues;
    if (!id) {
      createVideo({
        ...values, url: url.trim(), logo: logo && logo.length ? logo[0].originFileObj : undefined, coverImage: coverImage[0].originFileObj,
      });
    } else {
      const newUrl = url ? url.trim() : undefined;
      const newCoverImage = coverImage && typeof coverImage !== 'string' && coverImage.length ? coverImage[0].originFileObj : undefined;
      // const newLogo = logo && typeof logo !== 'string' && logo.length ? logo[0].originFileObj : undefined;
      let newLogo: any;

      if (logo) {
        if (typeof logo === 'string') {
          newLogo = logo;
        } else if (logo.length) {
          newLogo = logo[0].originFileObj;
        }
      }

      updateVideo({
        id, coverImage: newCoverImage, logo: newLogo, url: newUrl, ...values,
      });
    }
  };

  return (
    <Container>
      <Header>{data ? `Edit ${data.data.title.en} video` : 'Create video'}</Header>
      {/* <Ui.LocaleSelect localesList={localeOptions} onChangeHandler={setSelectedLanguages} selectedLanguages={selectedLanguages} /> */}
      <VideoForm onFormSubmit={onFormSubmit} languages={selectedLanguages} initialValues={data?.data} onDeleteClickHandler={onDeleteClickHandler} />
    </Container>
  );
}

const Container = styled.div`
  padding: 50px;
`;

const Header = styled.h1`
  margin-bottom: 30px;
`;
