import Button from './Button';
import Form from './Form';
import Input from './Input';
import InputWrap from './InputWrap';
import SideBar from './SideBar';
import TextArea from './Textarea';
import Title from './Title';
import ImageUploader from './ImageUploader';
import LocaleSelect from './LocaleSelect';
import MultilangInput from './MultilangInput';

export default {
  Button,
  Form,
  Input,
  InputWrap,
  SideBar,
  TextArea,
  Title,
  ImageUploader,
  LocaleSelect,
  MultilangInput,
};
