import styled from 'styled-components/macro';

const Button = styled.button<{ appearence?: 'danger' }>`
  background: ${({ appearence }) => (appearence === 'danger' ? '#e74741' : '#2682DE')};
  box-shadow: 0px 4px 6px #32475C1C;
  border-radius: 2px;
  border: none;
  box-sizing: border-box;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  color: #FCFDFF;
  cursor: pointer;
  box-shadow: 0px 4px 6px #32475C1C;
  padding: 10px;

  &:active {
    background: #2173C4;
    box-shadow: 0px 6px 6px #32475C1C;
  }
`;

export default Button;
