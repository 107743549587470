/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface CreateAuthDto
 */
export interface CreateAuthDto {
    /**
     *
     * @type {string}
     * @memberof CreateAuthDto
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof CreateAuthDto
     */
    password: string;
}
/**
 *
 * @export
 * @interface ResponseVideoDto
 */
export interface ResponseVideoDto {
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof ResponseVideoDto
     */
    title: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof ResponseVideoDto
     */
    description: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ResponseVideoDto
     */
    typeOfWork: { [key: string]: Array<string>; };
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ResponseVideoDto
     */
    category: { [key: string]: Array<string>; };
    /**
     *
     * @type {number | Null}
     * @memberof ResponseVideoDto
     */
    mainPageCell: number | null;
    /**
     *
     * @type {number | Null}
     * @memberof ResponseVideoDto
     */
    logoMaxWidth: number | null;
    /**
     *
     * @type {string}
     * @memberof ResponseVideoDto
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof ResponseVideoDto
     */
    videoSrc: string;
    /**
     *
     * @type {string}
     * @memberof ResponseVideoDto
     */
    coverImage: string;
    /**
     *
     * @type {string}
     * @memberof ResponseVideoDto
     */
    logo: string;
    /**
     *
     * @type {string}
     * @memberof ResponseVideoDto
     */
    id: string;
}
/**
 *
 * @export
 * @interface SuccessLoginDto
 */
export interface SuccessLoginDto {
    /**
     *
     * @type {string}
     * @memberof SuccessLoginDto
     */
    access_token: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerFeedBack: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling appControllerFeedBack.');
            }
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateAuthDto} createAuthDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerLogin: async (createAuthDto: CreateAuthDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthDto' is not null or undefined
            if (createAuthDto === null || createAuthDto === undefined) {
                throw new RequiredError('createAuthDto','Required parameter createAuthDto was null or undefined when calling appControllerLogin.');
            }
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAuthDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAuthDto !== undefined ? createAuthDto : {})
                : (createAuthDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerFeedBack(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).appControllerFeedBack(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {CreateAuthDto} createAuthDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerLogin(createAuthDto: CreateAuthDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessLoginDto>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).appControllerLogin(createAuthDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerFeedBack(body: object, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).appControllerFeedBack(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateAuthDto} createAuthDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerLogin(createAuthDto: CreateAuthDto, options?: any): AxiosPromise<SuccessLoginDto> {
            return DefaultApiFp(configuration).appControllerLogin(createAuthDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appControllerFeedBack operation in DefaultApi.
 * @export
 * @interface DefaultApiAppControllerFeedBackRequest
 */
export interface DefaultApiAppControllerFeedBackRequest {
    /**
     *
     * @type {object}
     * @memberof DefaultApiAppControllerFeedBack
     */
    readonly body: object
}

/**
 * Request parameters for appControllerLogin operation in DefaultApi.
 * @export
 * @interface DefaultApiAppControllerLoginRequest
 */
export interface DefaultApiAppControllerLoginRequest {
    /**
     *
     * @type {CreateAuthDto}
     * @memberof DefaultApiAppControllerLogin
     */
    readonly createAuthDto: CreateAuthDto
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {DefaultApiAppControllerFeedBackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerFeedBack(requestParameters: DefaultApiAppControllerFeedBackRequest, options?: any) {
        return DefaultApiFp(this.configuration).appControllerFeedBack(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiAppControllerLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerLogin(requestParameters: DefaultApiAppControllerLoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).appControllerLogin(requestParameters.createAuthDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideoApi - axios parameter creator
 * @export
 */
export const VideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {object} title
         * @param {object} description
         * @param {object} typeOfWork
         * @param {object} category
         * @param {number | Null} mainPageCell
         * @param {string} url
         * @param {string} videoSrc
         * @param {object} coverImage
         * @param {number | Null} [logoMaxWidth]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerCreate: async (title: object, description: object, typeOfWork: object, category: object, mainPageCell: number | null, url: string, videoSrc: string, coverImage: object, logoMaxWidth: number | null, logo?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            if (title === null || title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'description' is not null or undefined
            if (description === null || description === undefined) {
                throw new RequiredError('description','Required parameter description was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'typeOfWork' is not null or undefined
            if (typeOfWork === null || typeOfWork === undefined) {
                throw new RequiredError('typeOfWork','Required parameter typeOfWork was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'mainPageCell' is not null or undefined
            if (mainPageCell === undefined) {
                throw new RequiredError('mainPageCell','Required parameter mainPageCell was undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'videoSrc' is not null or undefined
            if (videoSrc === null || videoSrc === undefined) {
                throw new RequiredError('videoSrc','Required parameter videoSrc was null or undefined when calling videoControllerCreate.');
            }
            // verify required parameter 'coverImage' is not null or undefined
            if (coverImage === null || coverImage === undefined) {
                throw new RequiredError('coverImage','Required parameter coverImage was null or undefined when calling videoControllerCreate.');
            }
            const localVarPath = `/video/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (title !== undefined) {
                localVarFormParams.append('title', JSON.stringify(title));
            }

            if (description !== undefined) {
                localVarFormParams.append('description', JSON.stringify(description));
            }

            if (typeOfWork !== undefined) {
                localVarFormParams.append('typeOfWork', JSON.stringify(typeOfWork));
            }

            if (category !== undefined) {
                localVarFormParams.append('category', JSON.stringify(category));
            }

            if (mainPageCell !== undefined) {
                localVarFormParams.append('mainPageCell', JSON.stringify(mainPageCell));
            }

            if (logoMaxWidth !== undefined) {
                localVarFormParams.append('logoMaxWidth', JSON.stringify(logoMaxWidth));
            }

            if (url !== undefined) {
                localVarFormParams.append('url', JSON.stringify(url));
            }

            if (videoSrc !== undefined) {
                localVarFormParams.append('videoSrc', JSON.stringify(videoSrc));
            }

            if (coverImage !== undefined) {
                localVarFormParams.append('coverImage', coverImage as Blob);
            }

            if (logo !== undefined) {
                localVarFormParams.append('logo', logo as Blob);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/video`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }



            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindByUrl: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling videoControllerFindByUrl.');
            }
            const localVarPath = `/video/by-url/{url}`
                .replace(`{${"url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindOne: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling videoControllerFindOne.');
            }
            const localVarPath = `/video/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling videoControllerRemove.');
            }
            const localVarPath = `/video/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {object} [title]
         * @param {object} [description]
         * @param {object} [typeOfWork]
         * @param {object} [category]
         * @param {number | Null} [mainPageCell]
         * @param {number | Null} [logoMaxWidth]
         * @param {string} [url]
         * @param {string} [videoSrc]
         * @param {object} [coverImage]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerUpdate: async (id: string, title?: object, description?: object, typeOfWork?: object, category?: object, mainPageCell?: number | null, logoMaxWidth?: number | null, url?: string, videoSrc?: string, coverImage?: object, logo?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling videoControllerUpdate.');
            }
            const localVarPath = `/video/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (title !== undefined) {
                localVarFormParams.append('title', JSON.stringify(title));
            }

            if (description !== undefined) {
                localVarFormParams.append('description', JSON.stringify(description));
            }

            if (typeOfWork !== undefined) {
                localVarFormParams.append('typeOfWork', JSON.stringify(typeOfWork));
            }

            if (category !== undefined) {
                localVarFormParams.append('category', JSON.stringify(category));
            }

            if (mainPageCell !== undefined) {
                localVarFormParams.append('mainPageCell', JSON.stringify(mainPageCell));
            }

            if (logoMaxWidth !== undefined) {
                localVarFormParams.append('logoMaxWidth', JSON.stringify(logoMaxWidth));
            }

            if (url !== undefined) {
                localVarFormParams.append('url', JSON.stringify(url));
            }

            if (videoSrc !== undefined) {
                localVarFormParams.append('videoSrc', JSON.stringify(videoSrc));
            }

            if (coverImage !== undefined) {
                if (typeof coverImage !== 'string') {
                    localVarFormParams.append('coverImage', coverImage as Blob);
                } else {
                    localVarFormParams.append('coverImage', JSON.stringify(coverImage));
                }
            }

            if (logo !== undefined) {
                if (typeof logo !== 'string') {
                    localVarFormParams.append('logo', logo as Blob);
                } else {
                    localVarFormParams.append('logo', JSON.stringify(logo));
                }
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApi - functional programming interface
 * @export
 */
export const VideoApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {object} title
         * @param {object} description
         * @param {object} typeOfWork
         * @param {object} category
         * @param {number | Null} mainPageCell
         * @param {string} url
         * @param {string} videoSrc
         * @param {object} coverImage
         * @param {number | Null} [logoMaxWidth]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerCreate(title: object, description: object, typeOfWork: object, category: object, mainPageCell: number | null, url: string, videoSrc: string, coverImage: object, logoMaxWidth: number | null, logo?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseVideoDto>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerCreate(title, description, typeOfWork, category, mainPageCell, url, videoSrc, coverImage, logoMaxWidth, logo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseVideoDto>>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerFindByUrl(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseVideoDto>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerFindByUrl(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerFindOne(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseVideoDto>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {object} [title]
         * @param {object} [description]
         * @param {object} [typeOfWork]
         * @param {object} [category]
         * @param {number | Null} [mainPageCell]
         * @param {number | Null} [logoMaxWidth]
         * @param {string} [url]
         * @param {string} [videoSrc]
         * @param {object} [coverImage]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoControllerUpdate(id: string, title?: object, description?: object, typeOfWork?: object, category?: object, mainPageCell?: number | null, logoMaxWidth?: number | null, url?: string, videoSrc?: string, coverImage?: object, logo?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseVideoDto>> {
            const localVarAxiosArgs = await VideoApiAxiosParamCreator(configuration).videoControllerUpdate(id, title, description, typeOfWork, category, mainPageCell, logoMaxWidth, url, videoSrc, coverImage, logo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VideoApi - factory interface
 * @export
 */
export const VideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {object} title
         * @param {object} description
         * @param {object} typeOfWork
         * @param {object} category
         * @param {number | Null} mainPageCell
         * @param {string} url
         * @param {string} videoSrc
         * @param {object} coverImage
         * @param {number | Null} [logoMaxWidth]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerCreate(title: object, description: object, typeOfWork: object, category: object, mainPageCell: number | null, url: string, videoSrc: string, coverImage: object, logoMaxWidth: number | null, logo?: object, options?: any): AxiosPromise<ResponseVideoDto> {
            return VideoApiFp(configuration).videoControllerCreate(title, description, typeOfWork, category, mainPageCell, url, videoSrc, coverImage, logoMaxWidth, logo, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindAll(options?: any): AxiosPromise<Array<ResponseVideoDto>> {
            return VideoApiFp(configuration).videoControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindByUrl(url: string, options?: any): AxiosPromise<ResponseVideoDto> {
            return VideoApiFp(configuration).videoControllerFindByUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerFindOne(id: string, options?: any): AxiosPromise<ResponseVideoDto> {
            return VideoApiFp(configuration).videoControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return VideoApiFp(configuration).videoControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {object} [title]
         * @param {object} [description]
         * @param {object} [typeOfWork]
         * @param {object} [category]
         * @param {number | Null} [mainPageCell]
         * @param {number | Null} [logoMaxWidth]
         * @param {string} [url]
         * @param {string} [videoSrc]
         * @param {object} [coverImage]
         * @param {object} [logo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoControllerUpdate(id: string, title?: object, description?: object, typeOfWork?: object, category?: object, mainPageCell?: number | null, logoMaxWidth?: number | null, url?: string, videoSrc?: string, coverImage?: object, logo?: object, options?: any): AxiosPromise<ResponseVideoDto> {
            return VideoApiFp(configuration).videoControllerUpdate(id, title, description, typeOfWork, category, mainPageCell, logoMaxWidth, url, videoSrc, coverImage, logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for videoControllerCreate operation in VideoApi.
 * @export
 * @interface VideoApiVideoControllerCreateRequest
 */
export interface VideoApiVideoControllerCreateRequest {
    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly title: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly description: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly typeOfWork: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly category: object

    /**
     *
     * @type {number | Null}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly mainPageCell: number | null

    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly url: string

    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly videoSrc: string

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly coverImage: object

    /**
     *
     * @type {number}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly logoMaxWidth: number | null

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerCreate
     */
    readonly logo?: object
}

/**
 * Request parameters for videoControllerFindByUrl operation in VideoApi.
 * @export
 * @interface VideoApiVideoControllerFindByUrlRequest
 */
export interface VideoApiVideoControllerFindByUrlRequest {
    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerFindByUrl
     */
    readonly url: string
}

/**
 * Request parameters for videoControllerFindOne operation in VideoApi.
 * @export
 * @interface VideoApiVideoControllerFindOneRequest
 */
export interface VideoApiVideoControllerFindOneRequest {
    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for videoControllerRemove operation in VideoApi.
 * @export
 * @interface VideoApiVideoControllerRemoveRequest
 */
export interface VideoApiVideoControllerRemoveRequest {
    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for videoControllerUpdate operation in VideoApi.
 * @export
 * @interface VideoApiVideoControllerUpdateRequest
 */
export interface VideoApiVideoControllerUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly id: string

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly title?: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly description?: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly typeOfWork?: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly category?: object

    /**
     *
     * @type {number | null}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly mainPageCell?: number | null

    /**
     *
     * @type {number}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly logoMaxWidth?: number | null

    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly url?: string

    /**
     *
     * @type {string}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly videoSrc?: string

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly coverImage?: object

    /**
     *
     * @type {object}
     * @memberof VideoApiVideoControllerUpdate
     */
    readonly logo?: object
}

/**
 * VideoApi - object-oriented interface
 * @export
 * @class VideoApi
 * @extends {BaseAPI}
 */
export class VideoApi extends BaseAPI {
    /**
     *
     * @param {VideoApiVideoControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerCreate(requestParameters: VideoApiVideoControllerCreateRequest, options?: any) {
        return VideoApiFp(this.configuration).videoControllerCreate(requestParameters.title, requestParameters.description, requestParameters.typeOfWork, requestParameters.category, requestParameters.mainPageCell, requestParameters.url, requestParameters.videoSrc, requestParameters.coverImage, requestParameters.logoMaxWidth, requestParameters.logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerFindAll(options?: any) {
        return VideoApiFp(this.configuration).videoControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VideoApiVideoControllerFindByUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerFindByUrl(requestParameters: VideoApiVideoControllerFindByUrlRequest, options?: any) {
        return VideoApiFp(this.configuration).videoControllerFindByUrl(requestParameters.url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VideoApiVideoControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerFindOne(requestParameters: VideoApiVideoControllerFindOneRequest, options?: any) {
        return VideoApiFp(this.configuration).videoControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VideoApiVideoControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerRemove(requestParameters: VideoApiVideoControllerRemoveRequest, options?: any) {
        return VideoApiFp(this.configuration).videoControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VideoApiVideoControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoControllerUpdate(requestParameters: VideoApiVideoControllerUpdateRequest, options?: any) {
        return VideoApiFp(this.configuration).videoControllerUpdate(requestParameters.id, requestParameters.title, requestParameters.description, requestParameters.typeOfWork, requestParameters.category, requestParameters.mainPageCell, requestParameters.logoMaxWidth, requestParameters.url, requestParameters.videoSrc, requestParameters.coverImage, requestParameters.logo, options).then((request) => request(this.axios, this.basePath));
    }
}


