import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { VideoTableRow } from './types';

interface TableProps {
  tableData: Array<VideoTableRow>;
  onTableItemClick: (id: string) => void;
}

export default function VideosTable({ tableData, onTableItemClick }: TableProps) {
  const tableConfig: ColumnsType<VideoTableRow> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'linkToPage',
      dataIndex: 'linkToPage',
      title: 'Link to page',
      render: (text: Text, record) => <LinkToPage onClick={() => onTableItemClick(record.linkToPage)}>{`Link to ${record.name} page`}</LinkToPage>,
    },
  ];

  return <Table<VideoTableRow> dataSource={tableData} columns={tableConfig} />;
}

const LinkToPage = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
