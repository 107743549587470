import { ApiAuthUser } from '../../project-types/users/api-types';
import LoginForm from './LoginForm';
import { useLoginAction } from '../../api/features/userApi';

export default function LoginPage() {
  const login = useLoginAction();

  const onFormSubmit = (values: ApiAuthUser['data']) => {
    login(values);
  };

  return (
    <LoginForm onFormSubmit={onFormSubmit} />
  );
}
