import React, { useState, MouseEvent } from 'react';
import './App.css';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import ROUTER_CONFIG from './router/router-config';
import SideBar from './ui/SideBar';
import LoginPage from './components/LoginPage/LoginPage';
import useAuth from './hooks/useAuth';

const { Header, Sider, Content } = Layout;

const queryClient = new QueryClient();

function App() {
  const isAuth = useAuth();
  const [collapse, setCollapse] = useState(false);

  const handleToggle = (e: MouseEvent) => {
    e.preventDefault();
    collapse ? setCollapse(false) : setCollapse(true);
  };

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <Router>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapse}>
            <SideBar isAuth={isAuth} />
          </Sider>
          <Layout>
            <Header>
              {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: handleToggle,
                style: { color: '#fff' },
              })}
              <span style={{ fontSize: 20, color: 'white', paddingLeft: 30 }}>Admin Panel</span>
            </Header>
            <StyledContent>
              <Switch>
                {!isAuth ? (
                  <>
                    <Route path={ROUTER_CONFIG.LOGIN_PAGE.path} exact>
                      <LoginPage />
                    </Route>
                    <Redirect to={ROUTER_CONFIG.LOGIN_PAGE.path} />
                  </>
                ) : (
                  <>
                    {Object.entries(ROUTER_CONFIG).map(([key, route]) => (
                      <Route exact={route.exact} path={route.path} key={key}>
                        {route.children}
                      </Route>
                    ))}
                    <Redirect to="/" />
                  </>
                )}
              </Switch>
            </StyledContent>
          </Layout>
        </Layout>
      </Router>
    </QueryClientProvider>
  );
}

const StyledContent = styled(Content)`
  margin: 24px 16px;
  padding: 24;
  min-height: calc(100vh - 114px);
  background: #fff;
`;

export default App;
