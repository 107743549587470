import axios, { AxiosRequestConfig } from 'axios';

// export const axiosBaseUrl =  process.env.REACT_APP_BACKEND || 'https://rimon-backend-3p3iwqh5hq-uc.a.run.app';
export const axiosBaseUrl =  process.env.REACT_APP_BACKEND || 'http://159.65.125.194:5000';

export const axiosConfig: AxiosRequestConfig = {
  baseURL: axiosBaseUrl,
};

const axiosBackendClient = axios.create(axiosConfig);

export const getToken = () => localStorage.getItem('token');

export const setToken = (token: string) => {
  axiosBackendClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem('token', token);
};

export const setTokenWithReload = (token: string) => {
  setToken(token);
  window.location.reload();
};

export const removeToken = () => {
  axiosBackendClient.defaults.headers.common.Authorization = '';
  localStorage.removeItem('token');
  window.location.reload();
};

export default axiosBackendClient;
