import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { Upload, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import getBase64 from '../utils/getBase64';

interface ImageUploaderProps {
  onChangeHandler: (props: any) => void;
  value?: Array<UploadFile<any>> | string;
  label: string;
}

export default function ImageUploader({ onChangeHandler, value, label }: ImageUploaderProps) {
  const [filelist, setFilelist] = useState<Array<UploadFile>>([]);
  const [previewFile, setPreviewFile] = useState('');
  const [isShowPreview, setIsShowPreview] = useState(false);

  useEffect(() => {
    if (!value) return;

    if (typeof value === 'string') {
      setFilelist([{
        name: value.split('/')[value.split('/').length - 1],
        status: 'done',
        url: `${value}`,
        uid: 'uid',
      }]);
    } else if (Array.isArray(value)) {
      setFilelist(value);
    }
  }, [value]);

  console.log(filelist);

  const onChangeFileHandler = async ({ fileList: newFileList }: UploadChangeParam<UploadFile<any>>) => {
    onChangeHandler(newFileList);
  };

  const handlePreview = async (file: UploadFile<any>) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj) as string;
    }

    if (file.url) {
      setPreviewFile(file.url);
      setIsShowPreview(true);
    } else if (file.preview) {
      setPreviewFile(file.preview);
      setIsShowPreview(true);
    }
  };

  const handleRemove = () => onChangeHandler([]);

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <>
      <StyledUploder accept="image/*" onChange={onChangeFileHandler} onPreview={handlePreview} onRemove={handleRemove} listType="picture-card" customRequest={dummyRequest} maxCount={1} fileList={filelist}>
        {filelist.length < 1 && <div>{label}</div>}
      </StyledUploder>
      <Modal width={800} visible={isShowPreview} title="Preview" footer={null} onCancel={() => setIsShowPreview(false)}>
        <img alt="" style={{ width: '100%' }} src={previewFile} />
      </Modal>
    </>
  );
}

const StyledUploder = styled(Upload)`
  width: unset;
`;
