import {
  Input, Select, InputNumber, InputProps,
} from 'antd';

const { TextArea, Password } = Input;

interface InputComponentProps extends InputProps {
  value: string;
  setFieldValue: (field: string, value: string) => void;
  placeholder?: string;
  name: string;
  inputType?: 'tags' | 'textarea' | 'input' | 'numberInput' | 'passwordInput';
  maxNumber?: string;
  minNumber?: string;
}

export default function InputComponent({
  value, setFieldValue, placeholder = '', name, inputType = 'input', maxNumber, minNumber, ...props
}: InputComponentProps) {
  return (
    <>
      {inputType === 'input' && <Input value={value} onChange={(e) => setFieldValue(name, e.target.value)} addonBefore={props.addonBefore} placeholder={placeholder} className={props.className} />}
      {inputType === 'textarea' && <TextArea rows={5} value={value} placeholder={placeholder} onChange={(e) => setFieldValue(name, e.target.value)} className={props.className} />}
      {inputType === 'tags' && <Select style={{ width: '100%' }} mode="tags" value={value} onChange={(val) => setFieldValue(name, val)} placeholder={placeholder} className={props.className} />}
      {inputType === 'numberInput' && <InputNumber value={value} onChange={(val) => setFieldValue(name, val)} placeholder={placeholder} style={props.style} className={props.className} min={minNumber} max={maxNumber} />}
      {inputType === 'passwordInput' && <Password value={value} onChange={(e) => setFieldValue(name, e.target.value)} placeholder={placeholder} style={props.style} className={props.className} />}
    </>
  );
}
