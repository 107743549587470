import { message } from 'antd';
import { useMutation } from 'react-query';
import { ApiAuthUser } from '../../project-types/users/api-types';
import axiosBackendClient, { axiosBaseUrl, setTokenWithReload } from '../axios-client';
import { DefaultApi } from '../API_GENERATED';

const userApi = new DefaultApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  axiosBackendClient,
);

export const useLoginAction = () => {
  // const queryClient = useQueryClient();

  const {
    mutate,
  } = useMutation('login', (values: ApiAuthUser['data']) => userApi.appControllerLogin({
    createAuthDto: values,
  }), {
    onSuccess: (value) => {
      setTokenWithReload(value.data.access_token);
      // queryClient.invalidateQueries('user');
    },
    onError: async () => message.error('Wrong email or password'),
  });

  return mutate;
};

// export const useUser = () => useQuery('user', () => ..., { retry: 0 });
