import LoginPage from '../components/LoginPage/LoginPage';
import VideoPage from '../components/VideoPage/VideoFormPage/VideoPage';
import VideoList from '../components/VideoPage/VideoList';
/* {ROUTER_PATH} */

const ROUTER_CONFIG = {
  LOGIN_PAGE: {
    path: '/login',
    getPath: () => '/login',
    children: <LoginPage />,
    isMenu: false,
    exact: true,
  },
  VIDEOS_LIST: {
    path: '/',
    getPath: () => '/',
    exact: true,
    children: <VideoList />,
    isMenu: true,
  },
  VIDEOS_FORM: {
    path: '/videos-form',
    getPath: () => '/videos-form',
    exact: true,
    children: <VideoPage />,
    isMenu: false,
  },
  VIDEO_PAGE: {
    path: '/video/:id',
    getPath: ({ id }: { id: string }) => `/video/${id}`,
    exact: true,
    children: <VideoPage />,
    isMenu: false,
  },
/* {ROUTER_CONF} */
};

export default ROUTER_CONFIG;
