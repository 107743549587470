import { message } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosBackendClient, { axiosBaseUrl } from '../axios-client';
import { VideoApi, VideoApiVideoControllerCreateRequest, VideoApiVideoControllerUpdateRequest } from '../API_GENERATED';

const videosApi = new VideoApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  axiosBackendClient,
);

export const VideoKeyFactory = {
  createVideo: ['createVideo'] as const,
  updateVideo: ['updateVideo'] as const,
  deleteVideo: ['deleteVideo'] as const,
  videos: ['videos'] as const,
  video: (id: string) => ['video', id] as const,
};

export const useCreateVideoAction = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(VideoKeyFactory.createVideo, (values: VideoApiVideoControllerCreateRequest) => videosApi.videoControllerCreate(values), {
    onError: async (error: any) => {
      let errorMsg = 'Something went wrong when creating video';

      if (error && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      }

      message.error(errorMsg);
    },
    onSuccess: () => {
      message.success('Video created successfully');
      queryClient.invalidateQueries(VideoKeyFactory.videos);
    },
  });

  return mutate;
};

export const useUpdateVideoAction = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(VideoKeyFactory.updateVideo, (values: VideoApiVideoControllerUpdateRequest) => videosApi.videoControllerUpdate(values), {
    onError: async (error: any) => {
      let errorMsg = 'Something went wrong when updating video';

      if (error && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      }

      message.error(errorMsg);
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(VideoKeyFactory.videos);
      queryClient.refetchQueries(VideoKeyFactory.video(variables.id.toString()));
      message.success('Video updated successfully');
    },
  });

  return mutate;
};

export const useDeleteVideoAction = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(VideoKeyFactory.deleteVideo, (id: string) => videosApi.videoControllerRemove({ id }), {
    onError: async () => message.error('Something gone wrong with video delete'),
    onSuccess: () => queryClient.invalidateQueries(VideoKeyFactory.videos),
  });

  return mutate;
};

export const useVideosQuery = () => useQuery(VideoKeyFactory.videos, () => videosApi.videoControllerFindAll());

export const useVideoQuery = (id: string | undefined) => useQuery(VideoKeyFactory.video(id || ''), () => videosApi.videoControllerFindOne({ id } as { id: string }), {
  enabled: !!id,
});
