import { Select } from 'antd';

const { Option } = Select;

interface LocaleSelectProps {
  localesList: Array<{ locale: string; langName: string | undefined }>;
  selectedLanguages: Array<string>;
  onChangeHandler: (value: Array<string>) => void;
}

export default function LocaleSelect({ localesList, selectedLanguages, onChangeHandler }: LocaleSelectProps) {
  const onChange = (value: Array<string>) => {
    if (value.length === 0) return;
    onChangeHandler(value);
  };

  return (
    <Select style={{ width: '100%' }} mode="multiple" placeholder="Select languages" value={selectedLanguages} onChange={onChange}>
      {localesList.map((locale) => <Option key={locale.locale} value={locale.locale} label={locale.langName}>{locale.langName}</Option>)}
    </Select>
  );
}
