import { message } from 'antd';
import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { request } from '../../api';
import { ApiAuthUser } from '../../project-types/users/api-types';
import { createAxiosConfig } from '../../project-types/common/axios';
import { HTTP_METHODS } from '../../project-types/common/http-types';

export const isAuth = createSlice({
  name: 'isAuth',
  initialState: false,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

const userState = combineReducers({
  isAuth: isAuth.reducer,
});

export default userState;

export const { setIsAuth } = isAuth.actions;

// export const signIn = (data: ApiAuthUser['data']): AppThunk => (dispatch) => {
//   request(createAxiosConfig<ApiAuthUser>({
//     url: ['/auth', '/', 'login'],
//     method: HTTP_METHODS.POST,
//     data,
//   }))
//     .then((response: { data: ApiAuthUser['successResponse'] }) => {
//       setToken(response.data.access_token);
//       dispatch(setIsAuth(true));
//     }).catch(() => message.error('Wrong username or password'));
// };

export const getIsAuth = (state: RootState) => state.user.isAuth;
